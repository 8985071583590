<template>
  <div
    class="form-group form-check form-check-radio"
    :class="[
      {
        'has-danger': !!errorMessage,
        'has-success': meta.valid,
        'input-group': hasIcon,
        'input-group-focus': focused,
        'has-label': label || $slots.label,
      },
      inputClasses,
    ]"
  >
    <slot>
        <label v-if="label" class="form-check-label" :class="labelClasses">
            <span v-if="required">*</span>
            <input
                class="form-control form-check-input"
                :name="name"
                :id="name+value"
                type="radio"
                :placeholder="placeholder"
                :value="inputValue"
                @input="handleChange"
                @blur="focused = false"
                @focus="focused = true"
            />
            <span class="form-check-sign"> </span>
            {{ label }}
        </label>
    </slot>

    <slot name="infoBlock"></slot>
    <slot name="helpBlock">
      <div
        class="text-danger invalid-feedback"
        style="display: block"
        :class="{ 'mt-2': hasIcon }"
        v-show="errorMessage"
      >
        {{ errorMessage }}
      </div>
    </slot>
  </div>
</template>

<script>
import { useField } from "vee-validate";

export default {
  name: "vee-radio",
  props: {
    addonRightIcon: String,
    addonLeftIcon: String,
    type: {
      type: String,
      default: "text",
    },
    value: {
      type: String,
      default: "",
    },
    placeholder: {
      type: String,
      default: "",
    },
    name: {
      type: String,
      required: true,
    },
    label: {
      type: String,
    },
    required: {
      type: Boolean,
    },
    labelClasses: {
      type: String,
      default: "",
    },
    inputClasses: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      focused: false,
    };
  },
  computed: {
    hasIcon() {
      const { addonRight, addonLeft } = this.$slots;
      return (
        addonRight !== undefined ||
        addonLeft !== undefined ||
        this.addonRightIcon !== undefined ||
        this.addonLeftIcon !== undefined
      );
    },
  },
  setup(props) {
    const { value: inputValue, errorMessage, handleChange, meta } = useField(
      props.name,
      undefined,
      {
        initialValue: props.value,
      }
    );

    return {
      handleChange,
      errorMessage,
      inputValue,
      meta,
    };
  },
};
</script>

<style></style>
