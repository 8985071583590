<template>
    <div>
        <a class="btn btn-primary" @click="Gettem">Get Logged In User</a>
        <a class="btn btn-primary" @click="Logout">Logout</a>
    </div>
</template>
<script>
import apiUsers from '@/Modules/Users/api/users';

export default {
    data() {
        return {
            res: ''
        };
    },
    methods: {
        Gettem () {
            console.log('Getting the logged in user');
            this.res = '';
            apiUsers.GetLoggedInUser().then(res => {
                this.res = res.data;
                this.$emit('setUser', this.res);
            }).catch(e => {
                console.log('Error: ', e.response.data);
            });
        },
        Logout() {
            apiUsers.Logout().then(() => {
                localStorage.removeItem('Auth');
                localStorage.removeItem('BT');
                this.$router.push({name: 'Login'});
            });
        }
    }
};
</script>
<style>
</style>
