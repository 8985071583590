let DefaultState = () => {
    return {
        id: 0,
        username: 'NLI',
        name: 'Not Logged In',
        email: 'Not@Logged.in',
        /*address: '1 Happy Street',
        city: 'Joyville',
        state: 'Euphoria',
        zip: '12345',
        phone: '(123) 456-7890',*/
        gravatar: 'NotLoggedIn',
        SuccessMessage: '',
        ErrorMessage: ''
    };
};

export default {
    state: DefaultState(),
    getters: {
        userGetUsername: state => () => {
            return state.username;
        },
        userGetEmail: state => () => {
            return state.email;
        },
        UsersGetAll: state => () => {
            return state.AllUsers;
        },
        userGetSuccessMessage: state => () => {
            let Message = state.SuccessMessage;
            state.SuccessMessage = '';
            return Message;
        },
    },
    mutations: {
        USER_RESET_STATE(state) {
            Object.assign(state, DefaultState());
        },
        USER_ADD(state, User) {
            state.AllUsers.push(User);
        },
        USER_UPDATE_USER(state, User) {
            for (let x in state.AllUsers) { if (state.AllUsers[x].id === User.id) { state.AllUsers[x] = User; } }
        },
        USER_REMOVE_USER(state, User) {
            for (let x in state.AllUsers) { if (state.AllUsers[x].id === User.id) { state.AllUsers.splice(x, 1); } }
        },
        USER_UPDATE(state, User) {
            state.id = User.id;
            state.username = User.username;
            state.name = User.name;
            state.email = User.email;
            state.gravatar = User.gravatar;
            state.Roles = User.Roles;
        },
        USER_UPDATE_SUCCESS(state, Message) {
            state.SuccessMessage = Message;
        }
    },
    actions: {
        User_ResetState({commit}) {
            commit('USER_RESET_STATE');
        },
        Users_AddUser({commit}, User) {
            commit('USER_ADD', User);
        },
        Users_UpdateUser({commit}, User) {
            commit('USER_UPDATE_USER', User);
        },
        Users_UpdateSelf({commit}, User) {
            commit('USER_UPDATE', User);
        },
        Users_RemoveUser({commit}, User) {
            commit('USER_REMOVE_USER', User);
        },
        User_UpdateSuccessMessage({commit}, Message) {
            commit('USER_UPDATE_SUCCESS', Message);
        }
    }
};
