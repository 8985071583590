<template>
  <div id="WorkWithMe-page">
    <div class="row">
      <div class="col-lg-10 m-auto">
        <card class="card-signup" no-footer-line headerBGColor="orange" headerClasses="text-center">
          <template v-slot:header>
            <h4 class="card-title">{{ titles[criterion-1] }}</h4>
            <ul class="nav nav-pills">
              <li v-for="(tab, index) in tabs"
                class="nav-item wizard-tab-link"
                :ref="`tab-${index}`"
                :data-background-color="criterion===index+1?'black':'orange'"
                @click="criterionNav(index+1)"
                style="width: 33.33%">
                {{ tab }}
              </li>
            </ul>
            <div v-if="false" class="social">
              <button class="btn btn-icon btn-round btn-twitter">
                <i class="fa fa-twitter"></i>
              </button>
              <button class="btn btn-icon btn-round btn-dribbble">
                <i class="fa fa-dribbble"></i>
              </button>
              <button class="btn btn-icon btn-round btn-facebook">
                <i class="fa fa-facebook"> </i>
              </button>
              <h5 class="card-description">or be classical</h5>
            </div>
          </template>

          <Form @submit="submitCriterion1" :validation-schema="schemaCriterion1" v-if="criterion===1" v-slot="{ isSubmitting }">
            <h4 class="text-center">Criterion 1: You must take my course</h4>
            <div class="d-flex flex-column flex-md-row">
              <div class="col-12 col-md-7">
                <p>
                  I've been a "loser" most of my adult life. I have had some successes here and there. I was able to keep things going for a few
                  years. However, this year was a pretty bad one for me. I've learned things throughout the years but, I've never had everything
                  I learned applied to my life all at once.
                </p>
                <p>
                  I plan on making 2023 the year I have everything applied. I have a plan but, for one area of my plan, I could use some help.
                  I could use help from Page 1 "Losers" and Beyond (not just Beyond).
                </p>
                <p>
                  There is a set of criteria (which is not hard to meet) that must be met before we can work together. The first criterion is:
                  you must take my course (which is free). I need you to be aware of some things that most people are not aware of. Fill out the
                  form on the right to sign up to take my course.
                </p>
              </div>
              <div class="col-12 col-md-5 text-center">
                <div v-if="errCriterion1" ref="eleErrCriterion1" class="text-danger text-center">{{ errCriterion1 }}</div>
                <vee-input
                  name="first_name"
                  type="text"
                  placeholder="First Name..."
                  :value="reg.first_name"
                  addon-left-icon="now-ui-icons text_caps-small"
                />

                <vee-input
                  name="last_name"
                  type="text"
                  placeholder="Last Name..."
                  :value="reg.last_name"
                  addon-left-icon="now-ui-icons text_caps-small"
                />

                <vee-input
                  name="username"
                  type="text"
                  placeholder="Username..."
                  :value="reg.username"
                  addon-left-icon="now-ui-icons users_circle-08"
                />

                <vee-input
                  name="email"
                  type="text"
                  placeholder="Email..."
                  :value="reg.email"
                  addon-left-icon="now-ui-icons ui-1_email-85"
                />

                <checkbox class="text-left" v-model="agree" v-if="false">
                  I agree to the <a href="#something">terms and conditions</a>.
                </checkbox>

                <n-button type="primary" native-type="submit" round size="lg" :disabled="disableCriterion1||isSubmitting">
                  Sign Up
                  <font-awesome-icon v-if="submittingCriterion1" class="loading" icon="arrows-rotate" />
                </n-button>
              </div>
            </div>
          </Form>

          <div v-if="criterion===2" class="row">
            <div class="col-12 col-lg-10 offset-lg-1">
              <h4 class="text-center">Criterion 2: You must apply it</h4>
              <p>
                I'm building a web app to help apply the things in the course to my life. I'm still working out some issues but, the most important part will
                be done by New Year's Day. Hopefully I can get another section completed by then as well. I am offering you the ability to use this web app to
                help you apply these things to your life.
              </p>
              <p v-if="before1123">
                Right now there is a pre-launch discount for the web app. This will give me the ability to focus more time on resolving these issues and ensure
                it gets done in time for the new year. Right now you can subscribe to the web app for $10/month or $100/year. You will get this rate every
                month/year for the length of your web app membership. On New Year's Day the price will go up to it's soon to be regular price of $20/month or
                $200/year so, now is the time to lock in the discounted price.
              </p>
              <p v-if="before1223" class="text-center text-danger">
                Discount time left: {{ discountTimeLeft }}
              </p>

              <Collapse v-if="(criterionReached<=2)">
                <CollapseItem title="I'm just going to check out the course" id="0" class="mb-0">
                  <p>
                    You want to check it out before you commit. There's nothing wrong with that. Before you settle on this option though, I want to let you know
                    that if you use the web app, you will be much more likely to meet criterion 3.
                  </p>
                  <p class="mb-0">
                    Your account has been created. Check your email for where and when the course will be available.
                  </p>
                </CollapseItem>
                <CollapseItem title="Sign me up on the monthly plan" id="1" class="mb-0">
                  <div class="font">
                    <p>
                      Excellent choice! Improving your life is priceless. It's worth way more than ${{price.monthly}}/month.
                    </p>
                    <p v-if="before1123">
                      When the price goes up after New Year's Day, you will still have the ${{total.monthly}}/month rate. Just be sure your web app membership
                      is never canceled.
                    </p>
                    <Cart :item="cartMonth" />
                    <StripeElementsVue interval="month" :disabled="disableCriterion2" :preSubmit="submitCriterion2" />
                  </div>
                </CollapseItem>
                <CollapseItem title="Sign me up on the yearly plan" id="2" class="mb-0">
                  <div class="text-left font">
                    <p>
                      That's what I'm talking about! Improving your life is priceless. It's worth way more than ${{price.yearly}}/year.
                    </p>
                    <p v-if="before1123">
                      When the price goes up after New Year's Day, you will still have the ${{total.yearly}}/year rate. Just be sure your web app membership
                      is never canceled.
                    </p>
                    <div class="text-center">
                      <Cart :item="cartYear" />
                      <StripeElementsVue interval="year" :disabled="disableCriterion2" :preSubmit="submitCriterion2" />
                    </div>
                  </div>
                </CollapseItem>
              </Collapse>
              <div v-else class="row">
                <div class="col-12 col-lg-10 offset-lg-1 alert alert-success">
                  Congratulations {{ reg.first_name }}! <span v-if="before1123">The program starts on New Year's Day! </span>Check your email for a receipt and
                  a link to the program. Thank you for your support and joining me on this journey!
                </div>
              </div>
            </div>
          </div>

          <div v-if="criterion===3" class="row">
            <div class="col-12 col-lg-10 offset-lg-1">
              <h4 class="text-center">Criterion 3: You must possess certain attributes</h4>
              <p>
                First off, I want to congratulate you for making a {{ reg.interval===12?'serious':'' }} commitment to make some {{ reg.interval===12?'serious':'' }}
                improvements in your life! I also want to thank you for your support and joining me on this journey!
              </p>
              <p>
                Unfortunately, these attributes can be faked. Because they can be faked, I will not be disclosing the attributes.
              </p>
              <p>
                Fortunately, given the things taught in my course, most people will begin to possess these attributes all on their own without any financial
                motivation. So don't worry about this third criterion. Most likely you will begin to meet it; especially if you meet criterion 2.
              </p>
              <p v-if="before1123">
                I know you are eager to get started but, the course won't be available until New Year's Day. Until then, spend some quality time with your loved
                ones, eat your favorite foods, and do you favorite things. Check your email for a link to the course.
              </p>
            </div>
          </div>

          <template v-slot:footer>
          </template>
        </card>
      </div>
    </div>
  </div>
</template>
<script>
import { Checkbox, Radio, VeeInput, VeeRadio, Collapse, CollapseItem } from "@/components";
import { Form } from "vee-validate";
import { ref, computed, onMounted } from 'vue';
import * as Yup from "yup";
import StripeElementsVue from "../../Components/StripeElements";
import Cart from '@/Modules/Users/Components/Cart';
import GetLoggedInUser from "../../Components/GetLoggedInUser";

import API from '@/api/API';
import apiSubscriptions from '@/Modules/Users/api/subscriptions';
import apiUsers from '@/Modules/Users/api/users';

export default {
  components: {
    GetLoggedInUser,
    Checkbox, Radio,
    VeeInput, VeeRadio,
    Collapse, CollapseItem,
    Form,
    Cart,
    StripeElementsVue
  },
  data() {
    return {
      agree: false,
      tabs: ['Criterion 1', 'Criterion 2', 'Criterion 3'],
      titles: ['Register', 'Apply', '????']
    };
  },
  setup() {
    let reg = ref({
      id: 0,
      first_name: '',
      last_name: '',
      username: '',
      email: '',
      register_form: '"Losers" Program',
      interval: 1
    });
    let criterion = ref(1);
    let criterionReached = ref(1);

    const criterionNav = (to) => {
      if (to > criterionReached.value || to === criterion.value) { return false; }
      criterion.value = to;
    };

    // Criterion 1
    const eleErrCriterion1 = ref(null);
    let errCriterion1 = ref('');
    let disableCriterion1 = ref(false);
    let submittingCriterion1 = ref(false);

    const submitCriterion1 = (values, actions) => {
      disableCriterion1.value = true;
      submittingCriterion1.value = true;
      reg.value = {...reg.value, ...values};
      apiUsers.Register(reg.value).then(res => {
        reg.value = {...reg.value, ...res.data.User};
        localStorage.setItem('BT', res.data.token);
        API.defaults.headers.common = {'Authorization': `bearer ${res.data.token}`}
        criterion.value = 2;
        criterionReached.value = 2;
        submittingCriterion1.value = false;
      }).catch(err => {
        console.log({err});
        if (err.response.status === 422) {
          for (let x in err.response.data.errors) { actions.setFieldError(x, err.response.data.errors[x][0]); }
        } else { errCriterion1.value = 'Something went wrong. Try again later.'; }
        disableCriterion1.value = false;
        submittingCriterion1.value = false;
      });
    };

    const schemaCriterion1 = Yup.object().shape({
      first_name: Yup.string().required().label("The First Name"),
      last_name: Yup.string().required().label("The Last Name"),
      username: Yup.string().required().label("The Username"),
      email: Yup.string().email().required().label("The Email"),
    });

    // Criterion 2
    let disableCriterion2 = ref(false);
    let submitCriterion2 = olStripeElements => {
      disableCriterion2.value = true;
      apiSubscriptions.Create(reg.value.id, {app: 'Intended Me', interval: olStripeElements.interval}).then(async res => {
        olStripeElements.clientSecret = res.data.stripe_client_secret;
        let paymentConfirmed = await olStripeElements.confirmPayment();
        paymentSuccess(paymentConfirmed);
        reg.value.interval = olStripeElements.interval === 'month' ? 1 : 12;
      }).catch(err => {
        console.log({err});
        disableCriterion2.value = false;
        if (err.response.status === 422) {
          for (let x in err.response.data.errors) { actions.setFieldError(x, err.response.data.errors[x][0]); }
        } else { errCriterion1.value = 'Something went wrong. Try again later.'; }
      });
    };

    const paymentSuccess = trueFalse => {
      if (trueFalse) {
        criterion.value = 3;
        criterionReached.value = 3;
        apiUsers.Logout().then(() => {
            localStorage.removeItem('BT');
        });
      } else {
        disableCriterion2.value = false;
      }
    };

    let now = ref(new Date());
    let newYear = new Date('2023-01-01T00:00:00');
    const dayAfterNewYear = new Date('2023-01-02:00:00');
    let before1123 = computed(() => {
      return now.value < newYear;
    });
    let before1223 = computed(() => {
      return now.value < dayAfterNewYear;
    });
    let discountTimeLeft = computed(() => {
      if (before1123.value) {
        let difference = newYear.getTime()-now.value.getTime();
        let days = Math.floor(difference/(1000*60*60*24));
        let hours = Math.floor((difference%(1000*60*60*24))/(1000*60*60));
        let minutes = Math.floor((difference%(1000*60*60))/(1000*60));
        let seconds = Math.floor((difference%(1000*60))/1000);
        return days+' Days, '+hours+' Hours, '+minutes+' Minutes, '+seconds+' Seconds';
      } else {
        return '0 Days, 0 Hours, 0 Minutes, 0 Seconds';
      }
    });
    setInterval(() => { now.value = new Date; }, 1000);
    let price = ref({
        monthly: 20,
        yearly: 200
    });
    let discount = computed(() => {
      return {
        monthly: before1123.value ? 10 : 0,
        yearly: before1123.value ? 100 : 0
      };
    });
    let total = computed(() => {
      return {
        monthly: price.value.monthly-discount.value.monthly,
        yearly: price.value.yearly-discount.value.yearly
      };
    });
    let cartMonth = computed(() => {
      return {
        name: reg.value.register_form,
        price: price.value.monthly,
        discount: discount.value.monthly
      };
    });
    let cartYear = computed(() => {
      return {
        name: reg.value.register_form,
        price: price.value.yearly,
        discount: discount.value.yearly
      };
    });

    return {
      reg,
      before1123,
      before1223,
      price,
      discountTimeLeft,
      total,
      criterion,
      criterionReached,
      criterionNav,
      eleErrCriterion1,
      errCriterion1,
      disableCriterion1,
      submittingCriterion1,
      schemaCriterion1,
      submitCriterion1,
      submitCriterion2,
      disableCriterion2,
      cartMonth,
      cartYear
    };
  },
};
</script>
<style lang="scss">
#WorkWithMe-page
{
  .card {
    .nav.nav-pills {
      padding: 7px;

      li { padding: 5px; }
    }

    .card-body {
      .font { font-size: 14px; color: rgb(44, 44, 44); }
      .collapse { background-color: #E1E1E1; }
      .align-left { text-align: left; }
      .align-right { text-align: right; }
      .border-left, .border-top { border-color: #6C757D !important; }
      .border-top-2 { border-width: 2px; }
    }
  }
}
</style>
