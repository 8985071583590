import { createStore } from 'vuex';

import Users from './Modules/Users/store';

export const store = new createStore({
    state: {
        ReturnToPage: '',
        Site: {
            Domain: '',
            Name: process.env.VUE_APP_TITLE,
            Phones: {  },
            AuthLayout: {
                BG: ''
            }
        },
        Messages: { Success: '', Error: '', Info: '' },
    },
    getters: {
        getSuccessMessage: state => () => {
            let Message = state.Messages.Success;
            state.Messages.Success = '';
            return Message;
        },
        getErrorMessage: state => () => {
            let Message = state.Messages.Error;
            state.Messages.Error = '';
            return Message;
        }
    },
    mutations: {
        SET_SUCCESS_MESSAGE(state, Message) {
            state.Messages.Success = Message;
        },
        SET_ERROR_MESSAGE(state, Message) {
            state.Messages.Error = Message;
        },
        UPDATE_SITE_INFO(state, Info) {
            state.Site = Info;
        }
    },
    actions: {
        ResetState({commit}, Message) {
            // this.dispatch('UserResetState');
            commit('SET_'+Message.Type.toUpperCase()+'_MESSAGE', Message.Message);
        },
        SetMessage({commit}, Message)
        {
            commit('SET_'+Message.Type.toUpperCase()+'_MESSAGE', Message.Message);
        }
    },
    modules: {
        Users,
    }
});
