<template>
  <div class="not-found-page centered text-primary">
    <h1><i class="not-found-icon nc-icon nc-puzzle-10"></i>404</h1>
    <p>The page you requested could not be found.</p>
  </div>
</template>

<script>

export default {
  components: {
  },
};
</script>
<style lang="scss">
.not-found-page {
  .full-page > .content {
    min-height: calc(100vh - 160px);
  }
  .not-found-icon {
    margin-right: 10px;
  }
  &.centered {
    h1,
    i {
      font-size: 50px;
    }
    p {
      font-size: 20px;
    }
    display: flex;
    min-height: calc(100vh - 310px);
    // padding-bottom: 150px;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    color: white;
  }
}
</style>
