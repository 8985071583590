<template>
    <div id="Cart" class="container mb-3 border border-secondary font">
      <div class="row">
        <div class="col-8 col-md-10 align-left">{{ item.name }}</div>
        <div class="col-4 col-md-2 align-right border-left">${{item.price}}</div>
      </div>
      <div v-if="item.discount" class="row border-top">
        <div class="col-8 col-md-10 align-left">Discount</div>
        <div class="col-4 col-md-2 align-right border-left">${{item.discount}}</div>
      </div>
      <div class="row border-top border-top-2 font-weight-bold">
        <div class="col-8 col-md-10 align-right">Total:</div>
        <div class="col-4 col-md-2 align-right">${{total}}</div>
      </div>
    </div>
</template>

<script>
export default {
    props: {
        item: {
            type: Object,
            default: {
                name: '',
                price: 0,
                discount: 0,
            }
        }
    },
    computed: {
        total() {
            return this.item.price - this.item.discount;
        }
    }
};
</script>

<style lang="scss">
#Cart
{
    .align-left { text-align: left; }
    .align-right { text-align: right; }
    .border-left, .border-top { border-color: #6C757D !important; }
    .border-top-2 { border-width: 2px; }
}
</style>