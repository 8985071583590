<template>
  <div>
    <navbar id="nav">
      <div class="navbar-wrapper">
        <a class="navbar-brand" href="/">{{ SiteTitle }}</a>
      </div>

      <template v-slot:navbar-menu>
        <router-link class="nav-item" tag="li" :to="{name: 'Register'}">
          <a class="nav-link">Register</a>
        </router-link>
        <router-link class="nav-item" tag="li" :to="{name: 'Contact'}">
          <a class="nav-link">Contact</a>
        </router-link>
      </template>
    </navbar>
    <div class="wrapper wrapper-full-page">
      <div
        class="full-page section-image"
        :class="pageClass"
        filter-color="black"
        data-image="/img/bg13.jpg"
      >
        <div class="content">
          <div class="container">
            <router-view></router-view>
          </div>
        </div>
        <footer class="footer">
          <div class="container-fluid">
            <nav>
              <ul>
                <li>
                </li>
              </ul>
            </nav>
            <div class="copyright">
              &copy; {{ year }} <a href="//LosPrograms.com" rel="noopener">Los Programs</a>
              ALL RIGHTS RESERVED
            </div>
          </div>
        </footer>

        <div
          class="full-page-background"
          style="background-image: url('/img/bg13.jpg')"
        ></div>
      </div>
    </div>
  </div>
</template>
<script>
import { Navbar } from "@/components";
import API from '@/api/API';

export default {
  components: {
    Navbar,
  },
  props: {
    backgroundColor: {
      type: String,
      default: "black",
    },
  },
  data() {
    return {
      SiteTitle: process.env.VUE_APP_TITLE,
      pageTransitionDuration: 200,
      year: new Date().getFullYear(),
      pageClass: `${this.$route.name}-page`.toLowerCase(),
    };
  },
  created() {
    console.log('Route Name: ', this.$route.name, this.$route.name !== 'Not Found' && localStorage.getItem('Auth') === 'true');
    if (this.$route.name !== 'Not Found' && localStorage.getItem('Auth') === 'true') { this.$router.push('/'); }
    const token = localStorage.getItem('BT');
    if (token !== null) { API.defaults.headers.common = {'Authorization': `Bearer ${token}`}; }
  },
  methods: {
    setPageClass(to) {
      this.pageClass = `${to.name}-page`.toLowerCase();
    },
  },
  watch: {
    $route(to) {
      this.setPageClass(to);
    },
  },
};
</script>
<style lang="scss"></style>
