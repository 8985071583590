import AuthLayout from "src/Modules/Users/Layouts/AuthLayout.vue";

// Pages
import Login from "src/Modules/Users/Pages/Auth/Login.vue";
import Register from "src/Modules/Users/Pages/Auth/Register.vue";

let authPages = {
    path: "/",
    component: AuthLayout,
    name: "Authentication",
    children: [
        {
            path: "/Login",
            name: "Login",
            component: Login,
            meta: { guestOnly: true }
        },
        {
            path: "/Register",
            name: "Register",
            component: Register,
            // meta: { guestOnly: true }
        }
    ]
};

const routes = [
    authPages,
];

export default routes;
