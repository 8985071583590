import Api from '@/api/API';

export default {
    Find(subscriptionID) {
        return Api.get('/Auth/Subscriptions/'+subscriptionID);
    },
    Create(userID, data) {
        return Api.post('/Auth/Subscriptions/'+userID, data);
    }
};
